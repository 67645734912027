import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import TemplateHelpers from 'components/data/TemplateHelpers';

class FacebookSingle extends React.Component {
    static propTypes = {
        headline: PropTypes.string,
        linkDescription: PropTypes.string,
        displayUrl: PropTypes.string,
        cta: PropTypes.string,
        asset: PropTypes.object
    };

    static defaultProps = {
        hideLinkInfo: false
    };

    getAsset = () => {
        const { assetComponents, asset, image, video, hasVideo, language } = this.props;

        // Existing prop
        if (assetComponents) {
            // We have several ones for a dynamic ad
            if (assetComponents[0] && assetComponents[0].length > 1) {
                for (let i = 0; i < assetComponents[0].length; i++) {
                    const formatResult = TemplateHelpers.getFormatDimension(
                        assetComponents[0][i].props.data,
                        assetComponents[0][i].props.originalWidth,
                        assetComponents[0][i].props.originalHeight
                    );
                    if (formatResult === '4x5' || formatResult === '1x1') {
                        return assetComponents[0][i];
                    }
                }
            }

            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            let item = {
                asset,
                image,
                video,
                hasVideo
            };

            item = this.checkMultiLanguageVideo(item, language);

            return (
                <DynamicAsset
                    {...item.asset}
                    image={item.image}
                    video={item.video}
                    frameWidth={item.asset && item.asset.frameWidth ? item.asset.frameWidth : 1200}
                    frameHeight={item.asset && item.asset.frameHeight ? item.asset.frameHeight : 620}
                    width={320}
                />
            );
        }
    };

    checkMultiLanguageVideo = (item, language) => {
        // If there are different video's on multiple languages, only return the correct video in the specific language
        if (item.video && item.video[language]) {
            item.video = item.video[language];
        } else if (item.video && item.video.url === undefined) {
            // If the video is dependent on a language, but there is no video available in that language, item = video:null
            // item.video.url will be undefined, because the url will be inside of the language object (item.video.EN.url)
            item.video = null;
        }

        // If the hasVideo property is true, always show the video. However, if the hasVideo property exists, but is false, remove the video.
        if (item.hasVideo) {
            item.asset = null;
            item.image = null;
        } else if (typeof item.hasVideo !== 'undefined' && !item.hasVideo) {
            item.video = null;
        }

        return item;
    };

    render() {
        const { adType, cta, facebook_cta } = this.props;

        // Old structure
        const adFields = {
            link: this.props.link ? this.props.link : this.props.websiteUrl ? this.props.websiteUrl : this.props.displayUrl,
            name: this.props.name ? this.props.name : this.props.headline,
            description: this.props.description ? this.props.description : this.props.linkDescription,
            caption: this.props.caption ? this.props.caption : this.props.displayUrl ? this.props.displayUrl : this.websiteUrl
        };

        if (!adFields.caption) {
            adFields.caption = adFields.link;
        }

        const hasCTA = cta && cta !== '' && cta !== 'NO_BUTTON' && facebook_cta[cta] ? true : false;

        return (
            <div className="single">
                <div className="single__asset-wrapper">{this.getAsset()}</div>

                {adType !== 'post' && (
                    <div className="single__info">
                        <div className="single__info__text-wrapper">
                            <div className="single__info__text-wrapper__caption">{adFields.caption}</div>
                            <div className="single__info__text-wrapper__name">{adFields.name}</div>
                            <div className="single__info__text-wrapper__description">{adFields.description}</div>
                        </div>

                        {hasCTA && (
                            <div className="single__info__cta-wrapper">
                                <a href={adFields.link} target="_blank" className="single__info__cta-wrapper__cta">
                                    {facebook_cta[cta]}
                                </a>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default FacebookSingle;
